
export default function menu_primary() {

  var parent_id_primary = null;

  jQuery('.primary-menu-item').on('click', function() {
    parent_id_primary = jQuery(this).data('id')

    if (jQuery(`#submenu-item-container-${parent_id_primary}`).length > 0) {
      jQuery('.submenu-item-container').removeClass('active')
      jQuery(`#container-submenu, #submenu-item-container-${parent_id_primary}`).addClass('active')
      jQuery('.main-int-single-event').addClass('hidden-single-event')
    }
  })

  jQuery('#container-submenu').on('mouseleave', function() {
    jQuery(this).add('.child-element').removeClass('active inactive')
    jQuery('.main-int-single-event').removeClass('hidden-single-event')
    jQuery('.submenu-item-container').removeClass('active-child')
  })

  jQuery('.child-element').on('click', function() {
    const children = jQuery(this).children('div').length
    if (children > 0) {
      jQuery('.child-element').not(jQuery(this)).addClass('inactive')
      jQuery(`#submenu-item-container-${parent_id_primary}`).addClass('active-child')
      jQuery(this).addClass('active')
    }
  })

  jQuery('.come-back-menu').on('click', function(event) {
    jQuery('.child-element').removeClass('active inactive')
    jQuery(`#submenu-item-container-${parent_id_primary}`).removeClass('active-child')
    event.stopPropagation();
  })

  jQuery('.subchild-element').on('mouseenter', function() {
    const id = jQuery(this).data('id')
    const parent_id = jQuery(this).data('parent')
    
    jQuery(`.image-parent-${parent_id}, .subchild-parent-${parent_id}`).removeClass('active')
    jQuery(`#image-subchild-${id}`).add(this).addClass('active')

  })


  jQuery('.burger-menu').on('click', function() {
    jQuery('.container-menu-responsive').toggleClass('active')
  })
    
    console.log('menu.js')
}