import LazyLoad from "vanilla-lazyload";

export function searchAjax() {

    let params = {
        text: ""
    }

    const ajax_search = function(params) {
        jQuery.ajax({
            url: "/wp/wp-admin/admin-ajax.php",
            type: "POST",
            data: {
                action: "search_posts",
                text: params.text
            },
            success: function(data) {
                jQuery("#container-main-dynamic-search").replaceWith(data);
                var lazyLoad = new LazyLoad();
            }
        })
    }

    jQuery("#search, #close-search").on('click', function() {
        jQuery('.container-search-form').toggleClass('active')
    })

    jQuery('#search-website').on('change', function() {
        const val = jQuery('#search-website').val();
        if (val && val != "") {
            jQuery('.result-section').addClass('active')
            jQuery('.filter-section').addClass('inactive')
            params.text = val;
            ajax_search(params)
        } else {
            jQuery('.result-section, .filter-section').removeClass('active inactive')
        }
    })

    jQuery('#send-research-button').on('click', function() {
        const val = jQuery('#search-website').val();
        if (val && val != "") {
            jQuery('.result-section').addClass('active')
            jQuery('.filter-section').addClass('inactive')
            params.text = val;
            ajax_search(params)
        } else {
            jQuery('.result-section, .filter-section').removeClass('active inactive')
        }
    })

    jQuery('.tab-search').on('click', function() {
        const type = jQuery(this).data('type')
        jQuery('.tab-search, .item-search').removeClass('active inactive')
        jQuery(this).addClass('active')
        jQuery('.item-search').not(jQuery(`.item-search-${type}`)).addClass('inactive')
        jQuery('.result-section').addClass('active')
        jQuery('.filter-section').addClass('inactive')
    })

    jQuery('.most-research-item').on('click', function() {
        const val = jQuery(this).data('text')
        jQuery('#search-website').val(val)
    })
    
    jQuery('.open-filters').on('click', function() {
        jQuery('.result-section, .filter-section').removeClass('active inactive')
    })

    console.log('search.js')
}