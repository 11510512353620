import KeenSlider from "keen-slider";
function navigation(slider) {
  let wrapper, dots, arrowLeft, arrowRight;

  function markup(remove) {
    wrapperMarkup(remove);
    dotMarkup(remove);
    arrowMarkup(remove);
  }

  function removeElement(elment) {
    elment.parentNode.removeChild(elment);
  }
  function createDiv(className) {
    var div = document.createElement("div");
    var classNames = className.split(" ");
    classNames.forEach((name) => div.classList.add(name));
    return div;
  }

  function arrowMarkup(remove) {
    if (remove) {
      removeElement(arrowLeft);
      removeElement(arrowRight);
      return;
    }
    arrowLeft = createDiv("arrow arrow--left");
    arrowLeft.addEventListener("click", () => slider.prev());
    arrowRight = createDiv("arrow arrow--right");
    arrowRight.addEventListener("click", () => slider.next());

    wrapper.appendChild(arrowLeft);
    wrapper.appendChild(arrowRight);
  }

  function wrapperMarkup(remove) {
    if (remove) {
      var parent = wrapper.parentNode;
      while (wrapper.firstChild)
        parent.insertBefore(wrapper.firstChild, wrapper);
      removeElement(wrapper);
      return;
    }
    wrapper = createDiv("navigation-wrapper");
    slider.container.parentNode.appendChild(wrapper);
    wrapper.appendChild(slider.container);
  }

  function dotMarkup(remove) {
    if (remove) {
      removeElement(dots);
      return;
    }
    dots = createDiv("dots");
    slider.track.details.slides.forEach((_e, idx) => {
      var dot = createDiv("dot");
      dot.addEventListener("click", () => slider.moveToIdx(idx));
      dots.appendChild(dot);
    });
    wrapper.appendChild(dots);
  }

  function updateClasses() {
    var slide = slider.track.details.rel;
    slide === 0
      ? arrowLeft.classList.add("arrow--disabled")
      : arrowLeft.classList.remove("arrow--disabled");
    slide === slider.track.details.slides.length - 1
      ? arrowRight.classList.add("arrow--disabled")
      : arrowRight.classList.remove("arrow--disabled");
    Array.from(dots.children).forEach(function (dot, idx) {
      idx === slide
        ? dot.classList.add("dot--active")
        : dot.classList.remove("dot--active");
    });
  }

  slider.on("created", () => {
    markup();
    updateClasses();
  });
  slider.on("optionsChanged", () => {
    console.log(2);
    markup(true);
    markup();
    updateClasses();
  });
  slider.on("slideChanged", () => {
    updateClasses();
  });
  slider.on("destroyed", () => {
    markup(true);
  });
}

export default function sliders() {
  if (jQuery("#slider-events-home").length != 0) {
    let slider = new KeenSlider(
      "#slider-events-home",
      {
        loop: true,
        slides: {
          perView: 1,
          spacing: 25,
        },
        breakpoints: {
          "(min-width: 768px)": {
            slides: {
              perView: 1.5,
              spacing: 25,
            },
          },
          "(min-width: 1280px)": {
            slides: {
              perView: 1.75,
              spacing: 25,
            },
          },
          "(min-width: 1440px)": {
            slides: {
              perView: 2.5,
              spacing: 25,
            },
          },
        },
      },
      [
        (slider_events) => {
          let timeout;
          let mouseHover = false;

          function clearNextTimeout() {
            clearTimeout(timeout);
          }
          function nextTimeout() {
            clearTimeout(timeout);
            if (mouseHover) return;
            timeout = setTimeout(() => {
              slider_events.next();
            }, 2000);
          }

          slider_events.on("created", () => {
            slider_events.container.addEventListener("mouseover", () => {
              mouseHover = true;
              clearNextTimeout();
            });

            slider_events.container.addEventListener("mouseout", () => {
              mouseHover = false;
              nextTimeout();
            });
          });

          slider_events.on("dragStarted", clearNextTimeout);
          slider_events.on("animationEnded", nextTimeout);
          slider_events.on("updated", nextTimeout);
        },
        navigation,
      ]
    );
  }

  if (jQuery("#slider-actus-home").length != 0) {
    let slider_actualities = new KeenSlider("#slider-actus-home", {
      loop: false,
      slides: {
        perView: 1,
        spacing: 25,
      },
      breakpoints: {
        "(min-width: 768px)": {
          slides: {
            perView: 1.5,
            spacing: 25,
          },
        },
        "(min-width: 1280px)": {
          slides: {
            perView: 3,
            spacing: 15,
          },
        },
        "(min-width: 1440px)": {
          slides: {
            perView: 3,
            spacing: 75,
          },
        },
      },
    });
  }

  if (jQuery("#slider-program-home").length != 0) {
    let slider_programmation = new KeenSlider("#slider-program-home", {
      loop: false,
      slides: {
        perView: 1,
        spacing: 25,
      },
      breakpoints: {
        "(min-width: 768px)": {
          slides: {
            perView: 1.5,
            spacing: 25,
          },
        },
        "(min-width: 1280px)": {
          slides: {
            perView: 3,
            spacing: 25,
          },
        },
      },
    });
  }

  if (jQuery("#slider-header-home .keen-slider").length != 0) {
    let slider_header = new KeenSlider(
      "#slider-header-home",
      {
        loop: true,
        slides: {
          perView: 1,
        },
      },
      [
        (slider_header) => {
          let timeout;
          let mouseHover = false;

          function clearNextTimeout() {
            clearTimeout(timeout);
          }
          function nextTimeout() {
            clearTimeout(timeout);
            if (mouseHover) return;
            timeout = setTimeout(() => {
              slider_header.next();
            }, 7000);
          }

          slider_header.on("created", () => {
            slider_header.container.addEventListener("mouseover", () => {
              mouseHover = true;
              clearNextTimeout();
            });

            slider_header.container.addEventListener("mouseout", () => {
              mouseHover = false;
              nextTimeout();
            });
          });

          slider_header.on("dragStarted", clearNextTimeout);
          slider_header.on("animationEnded", nextTimeout);
          slider_header.on("updated", nextTimeout);
        },
      ]
    );

    jQuery(".prev-home-slider-header").on("click", function () {
      slider_header.prev();
    });
    jQuery(".next-home-slider-header").on("click", function () {
      slider_header.next();
    });
  }

  if (jQuery("#slider-partners-home").length != 0) {
    let slider_partners = new KeenSlider("#slider-partners-home", {
      loop: false,
      slides: {
        perView: 1.5,
        spacing: 25,
      },
      breakpoints: {
        "(min-width: 768px)": {
          slides: {
            perView: 3,
            spacing: 25,
          },
        },
      },
    });
  }

  if (jQuery("#slider-access-home").length != 0) {
    let slider_access = new KeenSlider("#slider-access-home", {
      loop: false,
      slides: {
        perView: 2.5,
        spacing: 5,
        // origin: 'center'
      },
      breakpoints: {
        "(min-width: 768px)": {
          slides: {
            perView: 3,
          },
        },
        "(min-width: 1280px)": {
          slides: {
            perView: 7,
          },
        },
      },
    });
  }

  if (jQuery("#slider-child-pages").length != 0) {
    let slider_childs_page = new KeenSlider(
      "#slider-child-pages",
      {
        loop: false,
        slides: {
          perView: 1,
          spacing: 15,
        },
        breakpoints: {
          "(min-width: 768px)": {
            slides: {
              perView: 2,
              spacing: 15,
            },
          },
          "(min-width: 1280px)": {
            slides: {
              perView: 3.25,
              spacing: 25,
            },
          },
        },
      },
      [navigation]
    );
  }

  console.log("sliders.js");
}
