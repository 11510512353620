

import LazyLoad from "vanilla-lazyload";
import init from "./common";
import sliders from "./modules/slider";

jQuery(function() {
  init();
  
  // Init Lazy load instance
  var lazyLoad = new LazyLoad();
  function sliderLazy() {
    lazyLoad.update();
  }
  
  sliders();
  
  console.log("home_js");
})

