// import "jquery.marquee";

import menu_primary from "./modules/menu";
import { searchAjax } from "./utils/search";

function eventFire(el, etype) {
  if (el.fireEvent) {
    el.fireEvent("on" + etype);
  } else {
    var evObj = document.createEvent("Events");
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}

export default function init() {

  // jQuery(".header__flash-infos .ticker").marquee({
  //   // duration: 10000,
  //   speed: 80,
  //   gap: 30,
  //   delayBeforeStart: 0,
  //   direction: "left",
  //   duplicated: true,
  //   pauseOnHover: true,
  //   startVisible: false,
  // });

  searchAjax();
  menu_primary();

  jQuery("#access-link").on("click", function () {
    eventFire(document.getElementById("uci_link"), "click");
  });

  // Listes Depliables
  jQuery(".liste-depliable__title").on("click", function () {
    jQuery(this).toggleClass("active");
    jQuery(this).next(".liste-depliable__content").slideToggle();
  });

  jQuery(document).on("click", 'a[href^="#"]', function (event) {
    event.preventDefault();
    console.log("click");
    jQuery("html, body").animate(
      {
        scrollTop: jQuery(jQuery.attr(this, "href")).offset().top,
      },
      600
    );
  });

  // Gtranslate

  function cleartimer() {
    setTimeout(function () {
      window.clearInterval(myVar);
    }, 1000);
  }
  function myTimer() {
    if (jQuery(".goog-te-combo option:first").length) {
      jQuery(".goog-te-combo option:contains('Sélectionner une langue')").html(
        "FR"
      );
      jQuery(".goog-te-combo option:contains('Anglais')").html("EN");
      jQuery(".goog-te-combo option:contains('Allemand')").html("DE");
      jQuery(".goog-te-combo option:contains('Espagnol')").html("ES");
      cleartimer();
    }
    jQuery(".goog-te-combo").on("change", function () {
      console.log("test");
      jQuery(".goog-te-combo option:contains('Sélectionner une langue')").html(
        "FR"
      );
      jQuery(".goog-te-combo option:contains('Anglais')").html("EN");
      jQuery(".goog-te-combo option:contains('Allemand')").html("DE");
      jQuery(".goog-te-combo option:contains('Espagnol')").html("ES");
      cleartimer();
    });
  }
  var myVar = setInterval(function () {
    myTimer();
  }, 300);

  const apparitionFunction = () => {
    let scrolledFromTop = jQuery(window).scrollTop()
    let windowHeight = jQuery(window).height();

    let maxHeight = scrolledFromTop + windowHeight

    jQuery('.appear').each(function () {
      let distance_from_top = jQuery(this).offset().top;
      if (maxHeight >= distance_from_top + 60) {
        jQuery(this).addClass('active-appear')
      }
    })
  }

  apparitionFunction();
  jQuery(window).on('scroll', function() {
    apparitionFunction()
  })

  console.log("common.js");
}
